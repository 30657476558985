.decoratedLink a,
.decoratedButton,
.headerDecoratedLink a,
.activeButton {
  position: relative;
}

.decoratedLink a:before,
.decoratedButton:before,
.headerDecoratedLink a:before,
.activeButton:before {
  content: '';
  position: absolute;
  width: 100%;
  height: 2px;
  bottom: -5px;
  left: 0px;
  right: 15px;
  background: #ff3652;
  visibility: hidden;
  transform: scaleX(0);
  transition: all 0.3s ease-in-out 0s;
}

.decoratedLink a:hover:before,
.decoratedButton:hover:before,
.headerDecoratedLink a:hover:before,
.active a:before,
.activeButton:before {
  visibility: visible;
  transform: scaleX(1);
}

.decoratedButton:before,
.activeButton:before {
  bottom: -3px;
}

.headerDecoratedLink a:before {
  bottom: -5px;
}

.prontoLabel {
  left: -15px !important;
  top: -5px !important;
  width: 50px;
}

.prontoLabelMobile {
  left: 48px !important;
  top: 3px !important;
  width: 50px;
}

.heartEmoji::before {
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  font-family: 'Font Awesome 5 Free';
  font-weight: 900;
  content: '\2665';
  padding-top: 3;
}

.red {
  color: #ff3652;
}
