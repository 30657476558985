.container {
  padding: 15px 0;
  background-color: #000;
  color: #fff;
}

.footerCol {
  padding: 5px;
  text-align: center;
}

.logoContainer {
  width: 100%;
}

.logo {
  align-self: center;
  margin: 10px auto;
  height: auto;
  max-width: 300px;
  width: 100%;
  /* filter: drop-shadow(0 0 0.75rem white); */
}

.text {
  font-size: .9rem;
  margin-bottom: 15px;
}

.textCenter {
  text-align: center;
}

.textRight {
  text-align: right;
}

.social {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.social ul {
  display: flex;
  flex-direction: row;
  list-style: none;
  margin: 0;
  position: relative;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.notCompact {
  margin-bottom: 30px;
}

.social li {
  display: inline-block;
  margin: 10px 20px;
  cursor: pointer;
}

.socialLiSm {
  margin: 10px 10px !important;
}

.social a {
  display: flex;
  height: 60px;
  width: 60px;
  align-items: center;
  justify-content: center;
}

.icon {
  color: #2b2b2b;
  background-color: transparent;
  border-radius: 50%;
  transition: all 0.3s ease;
  box-shadow: 0 0 15px 30px #fff inset;
}

.icon:hover {
  color: #fff;
  box-shadow: 0 0 15px 30px #ff3854 inset;
}

.topButton {
  cursor: pointer;
  display: inline-flex;
  width: 2.5rem;
  height: 2.5rem;
  color: #fff;
  background-color: #ff3652;
  border: none;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  outline: none;
  margin-bottom: 15px;
}

.footerLink:hover {
  text-decoration: underline;
}

.dondeEncontrarnosCompact {
  padding-left: 10px;
  flex-direction: column;
}

.links {
  text-align: center;
  margin-top: 20px;
}

.scrollToTop {
  position: relative;
  left: 90%;
}
